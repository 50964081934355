import React, {
    useContext,
  } from 'react'  
import { navigate } from "gatsby"
import {
    Frame,
    Page,
    Layout,
    Card,
    Subheading,
    Icon,
    Stack,
    Tooltip,
  } from "@shopify/polaris"
import {
    DetailedPopUpMajor,
    SidebarRightMajor,
    SectionMajor,
  } from '@shopify/polaris-icons';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import FirebaseContext from '../../../providers/firebase';
import styled from 'styled-components'
import CreateOfferSteps from './createOfferSteps'
const OfferTypeWrapper = styled.div`
    .offer-type-container {
      padding: 25px 150px 40px;
      .nickname-container {
        text-align: center;
        max-width: 430px;
        margin: auto;
        margin-bottom: 35px;
        .nickname-title {
            font-weight: bold;
            display: block;
            padding-bottom: 10px;
        }
      }
      .help-button {
        text-align: center;
        margin-top: 30px;
      }
      .offer-type {
        
        .offer-type-title {
            text-align: center;
            margin-bottom: 25px;
            h3 {
                color: #6D7175;
            }
        }
        .offer-type-option {
            height: 150px;
            width: 150px;
            padding: 20px 20px 5px;
            display: flex;
            flex-direction: column;
            justify-items: center;
            cursor: pointer;
            gap: 20px;
            .offer-type-option-container {
                display: flex;
                flex-direction: row;
                gap: 5px;
                justify-content: center;
                text-align: center;
                .Polaris-Icon {
                    margin-left: -27px;
                    margin-top: 0;
                }
                span {
                    color: #000;
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 1.2rem;
                }
            }
            .offer-enabled {
                svg {
                    fill: #007F5F;
                }
            }
            .offer-type-edit {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 30px;
                button {
                    background: #95C9B4;
                    border-color: #202223;
                }
                .Polaris-Icon {
                    width: 50px;
                    height: 50px;
                }
            }
            .upsell-badge {
                position: absolute;
                bottom: 10px;
                .Polaris-Badge {
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 1.2rem;
                }
            }
            .upsell-beta {
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%)
            }
        }
        .active {
            .Polaris-Card {
                background: #008060;
            }
            .offer-type-option-container {
                span {
                    color:#fff;
                }
            }
            svg {
                fill: #fff;
            }
        }

      }
      .errors {
          padding: 5px 0;
      }
    }
`

function CreateCartType(props) {
    const { location } = props
    const rules = location && location.state && location.state.rules
    const { firebase, shop, token, host } = useContext(FirebaseContext)
    
    const onOfferTypeChange = (offerType) => {
        if ("page" === offerType) {
            navigate('/app/offers/createCart', {
                state: { rules },
                replace: true,
            })
        }else{
            if("drawer" === offerType){
                navigate('/app/offers/createCartDrawer', {
                        state: { rules },
                        replace: true,
                })
            }
            if ("popup" === offerType) {
                navigate('/app/offers/cart/v1/createCart', {
                    state: { rules },
                    replace: true,
                })
            }
        }
    }
    return (
        <Frame>
        <Page 
      breadcrumbs={[{content: 'Offers', url: '/app/offers'}]}
      title={
        `Create Offer`
      }
      //primaryAction={{content: 'Save'}}
    >
        <Layout>
            <Layout.Section>
                <Card>
                <Card.Section>
                <CreateOfferSteps step={1}/>
                <OfferTypeWrapper>
                    <div className='offer-type-container'>
                        <div className='offer-type'>
                            <div className='offer-type-title'>
                                <Subheading>
                                What type of cart upsell do you want?
                                </Subheading>
                            </div>
                            <Stack distribution="center">

                            <div className="Polaris-Card" style={{position: 'relative'}}>
                                <Tooltip content={<div style={{padding:'10px'}}><p style={{fontWeight:'bold',marginBottom:'10px'}}>Popup cart upsell</p><p>This upsell will pop up on the bottom right of the page.</p></div>} dismissOnMouseOut>
                                    <div className="Polaris-Card">
                                        <div className='offer-type-option' onClick={() => {
                                            onOfferTypeChange('popup')
                                        }}>
                                            <div className="offer-type-option-container">
                                                <div style={{height:'41px'}}>
                                                    <span>Popup Upsell</span>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='offer-type-edit'>
                                                    <Icon
                                                      source={DetailedPopUpMajor}
                                                      color="base" />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </Tooltip>
                            </div>

                            <div className="Polaris-Card" style={{position: 'relative'}}>
                                <Tooltip content={<div style={{padding:'10px'}}><p style={{fontWeight:'bold',marginBottom:'10px'}}>Cart Page</p><p>Show a ‘You may also like’ section on your main cart page.</p></div>} dismissOnMouseOut>
                                <div className="Polaris-Card">
                                    <div className='offer-type-option' onClick={() => { 
                                            onOfferTypeChange('page')
                                        }}>
                                        <div className="offer-type-option-container">
                                            <div style={{height:'41px'}}>
                                                <span>Cart page upsell</span>
                                            </div>
                                        </div>
                                        <div>
                                        <div className='offer-type-edit'>
                                            <Icon
                                                source={SectionMajor}
                                                color="base" />
                                        </div>
                                        
                                        </div>
                                    </div>
                                </div>
                                </Tooltip>
                            </div>

                            <div>
                                <div className="Polaris-Card">
                                    <Tooltip content={<div style={{padding:'10px'}}><p style={{fontWeight:'bold',marginBottom:'10px'}}>Cart Drawer</p><p>This upsell will show in your side cart drawer or minicart</p></div>} dismissOnMouseOut>
                                    <div className="Polaris-Card">
                                        <div className='offer-type-option' onClick={() => { 
                                                onOfferTypeChange('drawer')
                                            }}>
                                            <div className="offer-type-option-container">
                                            
                                                <div>
                                                    <span>Cart Drawer Upsell</span>
                                                
                                                </div>
                                            </div>
                                            <div>
                                                <div className='offer-type-edit'>
                                                    <Icon
                                                        source={SidebarRightMajor}
                                                        color="base" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </Tooltip>
                                </div>
                            </div>
                            </Stack>
                            
                        </div>
                    </div>
                    
                    
                </OfferTypeWrapper>
                </Card.Section>
                </Card>
            </Layout.Section>
        </Layout>
    </Page>
   </Frame>
    )
}

export default CreateCartType;